import Nina from '@nina-protocol/js-sdk'
import { PaginationProps, RevenueShareRecipient } from '../types'
import initSdk from '../utils/initSdk'

export const fetchRevenueSharesStatsForAccount = async (
  publicKey: string,
  pagination = { limit: 1000, offset: 0, sort: 'desc' } as PaginationProps,
) => {
  await initSdk()

  const { revenueShares } = await Nina.Account.fetchRevenueShares(
    publicKey,
    pagination,
    true,
  )

  let owedSol = 0
  let collectedSol = 0
  let owedUsdc = 0
  let collectedUsdc = 0
  for await (const release of revenueShares) {
    const revenueShare =
      release.accountData.release.revenueShareRecipients.find(
        (share: RevenueShareRecipient) =>
          share.recipientAuthority === publicKey,
      )

    if (revenueShare) {
      if (
        Nina.constructor.isSol(
          release.accountData.release.paymentMint,
          process.env.SOLANA_RPC_CLUSTER_ENV,
          process.env.SOLANA_RPC_CLUSTER_ENV,
        )
      ) {
        owedSol += Nina.constructor.nativeToUi(
          revenueShare.owed,
          release.accountData.release.paymentMint,
          process.env.SOLANA_RPC_CLUSTER_ENV,
        )
        collectedSol += Nina.constructor.nativeToUi(
          revenueShare.collected,
          release.accountData.release.paymentMint,
          process.env.SOLANA_RPC_CLUSTER_ENV,
        )
      } else if (
        Nina.constructor.isUsdc(
          release.accountData.release.paymentMint,
          process.env.SOLANA_RPC_CLUSTER_ENV,
        )
      ) {
        owedUsdc += Nina.constructor.nativeToUi(
          revenueShare.owed,
          release.accountData.release.paymentMint,
          process.env.SOLANA_RPC_CLUSTER_ENV,
        )
        collectedUsdc += Nina.constructor.nativeToUi(
          revenueShare.collected,
          release.accountData.release.paymentMint,
          process.env.SOLANA_RPC_CLUSTER_ENV,
        )
      }
    }
  }

  const solPrice = await Nina.Wallet.getSolPrice()
  const totalEarnings = owedSol * solPrice + owedUsdc

  return {
    revenueShares,
    owedSol,
    collectedSol,
    owedUsdc,
    collectedUsdc,
    totalEarnings,
  }
}
